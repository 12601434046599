import trackerApi from "../api/tracker";

export const getCountGameFromAllAuthor = async () => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const response = await trackerApi.get("/getCountGameFromAllAuthor", {
      headers: { Authorization: `Bearer ${token}` },
      params: { token },
    });

    return response.data;
  } catch (error) {
    console.log("error getCountGameFromAllAuthor :", error);
  }
};

export const countAllGames = async () => {
  try {
    const response = await trackerApi.get("/countAllGames");
    return response.data;
  } catch (error) {
    console.log("error countAllGames :", error);
  }
};