import React, { useState, useEffect, useContext } from "react";
import "../assets/style/globalStyle.scss";
import { getCountPlaygroundModerateFromAllAuthor } from "../services/playgroundService";
import { getCountGameFromAllAuthor } from "../services/gameService";
import { getEmailById } from "../services/authService";
import { GlobalDispatchContext } from "../context/GlobalContextProvider";

const AuthorCountPlaygroundModerate = () => {
  const dispatch = useContext(GlobalDispatchContext);

  const [countPlaygroundModerate, setCountPlaygroundModerate] = useState([]);
  const [countGame, setCountGame] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [isGamedActive, setIsGamedActive] = useState(false);
  const [modalUpdateClassName, setModalUpdateClassName] = useState("modal");

  const getAllCountPlaygroundModerate = async () => {
    const data = await getCountPlaygroundModerateFromAllAuthor();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>Pas d'utilisateur trouvé, réessayez ultérieurement.</h1>
        </div>
      );
    }

    setCountPlaygroundModerate(data);
  };

  const getAllCountGame = async () => {
    const data = await getCountGameFromAllAuthor();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>Pas d'utilisateur trouvé, réessayez ultérieurement.</h1>
        </div>
      );
    }

    setCountGame(data);
  };

  const GetUserInfo = async (userId) => {
    let userEmail = await getEmailById(userId);
    setUserEmail(userEmail.email);

    GoToModal({ modalType: "info", showModal: true });
  };

  const GoToModal = async ({ modalType, showModal }) => {
    setModalUpdateClassName("modal is-active");
    dispatch({
      type: "show_modal",
      payload: showModal,
      modalType: modalType,
    });
  };

  const CloseModal = async ({ showModal }) => {
    setModalUpdateClassName("modal");
    dispatch({
      type: "show_modal",
      payload: showModal,
      modalType: "",
    });
  };

  useEffect(() => {
    getAllCountPlaygroundModerate();
    getAllCountGame();
  }, []);

  return (
    <div className="box">
      <div className="columns">
        <div className="column">
          <button
            className={
              isGamedActive
                ? "button is-outlined"
                : "button is-success is-outlined"
            }
            onClick={() => setIsGamedActive(false)}
          >
            Playgrounds ajoutés
          </button>
        </div>
        <div className="column">
          <button
            style={{ textAlign: "center" }}
            className={
              isGamedActive
                ? "button is-success is-outlined"
                : "button is-outlined"
            }
            onClick={() => setIsGamedActive(true)}
          >
            Games ajoutées
          </button>
        </div>
        {/* Export Local */}
        {/* <div className="column">
          <button
            style={{ textAlign: "center" }}
            className={"button is-link is-outlined"}
            onClick={() => navigate(`/app/allUserEmail`)}
          >
            Export email de tous les utilisateurs
          </button>
        </div> */}
      </div>
      <table className="table is-striped is-hoverable is-responsive">
        <thead>
          <tr>
            <th className="is-hidden-desktop" style={{ width: "200px" }}>
              <abbr title="User-name">Utilisateur</abbr>
            </th>
            <th className="is-hidden-mobile" style={{ width: "400px" }}>
              <abbr title="User-name">Utilisateur</abbr>
            </th>
            {!isGamedActive ? (
              <th>
                <abbr title="User-count">Nombre de terrains ajoutés</abbr>
              </th>
            ) : (
              <th>
                <abbr title="User-count">Nombre de parties ajoutées</abbr>
              </th>
            )}
          </tr>
        </thead>

        <tbody>
          {!isGamedActive
            ? countPlaygroundModerate.length !== 0 &&
              countPlaygroundModerate.countPlaygroundModerateFromAllAuthor.map(
                (user) => (
                  <tr key={user._id._id} style={{ height: "45px" }}>
                    <td className="is-hidden-desktop">
                      {user._id.userName} | {user._id.firstName}
                    </td>
                    <td className="is-hidden-mobile">
                      <div className="panel-block">
                        {user._id.image ? (
                          <figure className="image is-48x48">
                            <img
                              className="is-rounded"
                              src={`https://res.cloudinary.com/ball-n-connect/image/upload/v1/profile_image/${user._id.userId}.jpg`}
                              alt=""
                            />
                          </figure>
                        ) : (
                          <figure className="image is-48x48">
                            <img
                              className="is-rounded"
                              src={"/assets/img/appareil_photo.png"}
                              alt=""
                            />
                          </figure>
                        )}
                        <p className="px-4">
                          {user._id.userName} | {user._id.firstName}
                        </p>
                      </div>
                    </td>
                    <td>{user.countPlayground}</td>
                    <td>
                      <button
                        className="button is-primary"
                        onClick={() => GetUserInfo(user._id.userId)}
                      >
                        <span className="icon is-medium">
                          <span className="fa-stack">
                            <i className="fas fa-circle fa-stack-2x"></i>
                          </span>
                        </span>
                      </button>
                    </td>
                  </tr>
                )
              )
            : countGame.length !== 0 &&
              countGame.countGameFromAllAuthor.map((user) => (
                <tr key={user._id._id} style={{ height: "45px" }}>
                  <td className="is-hidden-desktop">
                    {user._id.userName} | {user._id.firstName}
                  </td>
                  <td className="is-hidden-mobile">
                    <div className="panel-block">
                      {user._id.image ? (
                        <figure className="image is-48x48">
                          <img
                            className="is-rounded"
                            src={`https://res.cloudinary.com/ball-n-connect/image/upload/v1/profile_image/${user._id.userId}.jpg`}
                            alt=""
                          />
                        </figure>
                      ) : (
                        <figure className="image is-48x48">
                          <img
                            className="is-rounded"
                            src={"/assets/img/appareil_photo.png"}
                            alt=""
                          />
                        </figure>
                      )}
                      <p className="px-4">
                        {user._id.userName} | {user._id.firstName}
                      </p>
                    </div>
                  </td>
                  <td>{user.countGame}</td>
                  <td>
                    <button
                      className="button is-primary"
                      onClick={() => GetUserInfo(user._id.userId)}
                    >
                      <span className="icon is-medium">
                        <span className="fa-stack">
                          <i className="fas fa-circle fa-stack-2x"></i>
                        </span>
                      </span>
                    </button>
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
      <div className={modalUpdateClassName}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Infos utilisateur</p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => CloseModal({ showModal: false })}
            />
          </header>
          <section className="modal-card-body">
            Contact : <a href={"mailto:" + userEmail}>{userEmail}</a>
          </section>
          <footer className="modal-card-foot">
            <button
              className="button"
              onClick={() => CloseModal({ showModal: false })}
            >
              Fermer
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default AuthorCountPlaygroundModerate;
